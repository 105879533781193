.react-tel-input .form-control.inputClass {
  font: inherit;
  color: #262626;
  font-size: 16px;
  line-height: 24px;
  border-radius: 2px;
  padding: 8px 34px 8px 42px;
  border: 1px solid #d9d9d9;
  height: unset;
  width: 100%;
}
.react-tel-input .form-control.inputClass:focus {
  border-color: #1890ff;
  box-shadow: 0 0 4px rgba(24, 144, 255, 0.5);
}
