/* .m6zwb4v,
.m6zwb4v:visited {
  color: #575f67;
  cursor: pointer;
  display: inline-block;
  background: #e6f3ff;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
  -webkit-text-decoration: none;
  text-decoration: none;
} */
.m6zwb4v,
.m6zwb4v:visited {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #262626;
  margin: 0 8px;
  padding: 1px 8px;
  width: -webkit-max-content;
  width: max-content;
  font-size: 12px;
  line-height: 20px;
}
.m6zwb4v:hover,
.m6zwb4v:focus {
  color: #677584;
  background: #edf5fd;
  outline: 0;
}
.m6zwb4v:active {
  color: #222;
  background: #455261;
}
.mnw6qvm {
  border: 1px solid #eee;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  max-height: 30vh;
  overflow-y: scroll;
  scrollbar-width: thin;
  -webkit-scrollbar-width: thin;
}

.m1ymsnxd {
  opacity: 0;
  -webkit-transition: opacity 0.25s cubic-bezier(0.3, 1.2, 0.2, 1);
  transition: opacity 0.25s cubic-bezier(0.3, 1.2, 0.2, 1);
}
.m126ak5t {
  opacity: 1;
}
.mtiwdxc {
  padding: 7px 10px 3px 10px;
  -webkit-transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}
.mtiwdxc:active {
  background-color: #cce7ff;
}
.myz2dw1 {
  padding: 7px 10px 3px 10px;
  -webkit-transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  background-color: #e6f3ff;
}
.myz2dw1:active {
  background-color: #cce7ff;
}
.mpqdcgq {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
  margin-bottom: 0.2em;
}

.m1mfvffo {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
}
